import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';
import FormHelperText from '@mui/material/FormHelperText';
import BeatLoader from 'react-spinners/BeatLoader';
import { ButtonRow, FormStepper, FormInput, useTrackedFormik } from '@omnigenbiodata/ui';
import InnerLayout from '../../../../layout/Inner';
import { useAppDispatch } from '../../../../store';
import { stepForward, resetEnrolment } from '../../../../store/enrolment';
import { HOSPTIAL_NUM_PREFIX, ENROLMENT_STEPS, MESSAGES } from '../../../../core/constants/forms.constant';
import ROUTES from '../../../../core/constants/routes.constant';
import participantApi from '../../../../store/participant/participantApi';

const validationSchema = yup.object({
  ialch: yup
    .string()
    .required(MESSAGES.hospitalNumRequired)
    .transform((value: string) => (value.startsWith('KZ') ? value.slice(2) : value))
    .matches(/^[0-9]+$/, MESSAGES.hospitalNumFormatError)
    .min(8, MESSAGES.hospitalNumLengthError)
    .max(8, MESSAGES.hospitalNumLengthError)
    .test('asyncIalchLookup', MESSAGES.hospitalNumExistsError, async (value) => {
      if (value?.length === 8) {
        try {
          const { data } = await participantApi.lookupParticipant({
            ialchID: value,
          });
          if (data?.studyParticipant) {
            return false;
          }
        } catch (error: any) {
          return true;
        }
        return true;
      }
      return false;
    }),
});

function HospitalNum() {
  const dispatch = useAppDispatch();
  const navigateTo = useNavigate();
  const formik = useTrackedFormik(
    {
      initialValues: {
        ialch: '',
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        dispatch(stepForward(values));
        navigateTo(ROUTES.enrolmentDepartment);
      },
    },
    'enrol-hospital-num',
  );

  useEffect(() => {
    dispatch(resetEnrolment());
  }, [dispatch]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldValue(
      'ialch',
      e.target.value.startsWith(HOSPTIAL_NUM_PREFIX) ? e.target.value.slice(2) : e.target.value,
    );
  };

  return (
    <InnerLayout>
      <form onSubmit={formik.handleSubmit} data-testid="step-form">
        <Box my={3}>
          <Box my={3}>
            <FormStepper steps={ENROLMENT_STEPS} activeStep={0} />
          </Box>
        </Box>
        <Typography variant="h4" component="h1" align="center" gutterBottom>
          Participant Identification
        </Typography>

        <Typography paragraph variant="body1" align="center">
          Please enter the participant IALCH ID below:
        </Typography>
        <Box mb={8}>
          <FormInput
            error={!formik.isValidating && formik.errors.ialch ? formik.errors.ialch : undefined}
            name="ialch"
            label="IALCH ID number"
            onChange={handleChange}
            onBlur={formik.handleBlur}
            touched={formik.touched.ialch}
            value={formik.values.ialch}
            startAdornment={<>{HOSPTIAL_NUM_PREFIX}</>}
          />
          {formik.touched.ialch && formik.isValidating && (
            <FormHelperText>
              <BeatLoader color={'#cccccc'} loading={formik.isValidating} size={7} />
            </FormHelperText>
          )}
        </Box>
        <ButtonRow showBack={false} buttonSize="large" forwardColor="primary" />
      </form>
    </InnerLayout>
  );
}

export default HospitalNum;
